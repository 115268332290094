import Vue from "vue";
import AxiosHttp from "../AxiosHttp";
import Usuario from "./Usuario";
import UsuarioPessoa from "./UsuarioPessoa";

export default class SegurancaService extends AxiosHttp {
    constructor() {
        super();
    }

    
    static get PERFIL_MARKETING() {
        return "Marketing Digital";
    }

    static get PERFIL_ALUNO() {
        return "Aluno";
    }

    static get PERFIL_VIDEOS() {
        return "Vídeos";
    }

    static get PERFIL_ADM() {
        return "Administrador";
    }

    static get PERFIL_ESTAGIARIO() {
        return "Estagiário";
    }

    loggedUser() {
        return this.http.get("loggedUser").then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    buscarUsuarios(textoPesquisa: string): Promise<Array<Usuario>> {
        return this.http.get("usuario/busca", { params: { textoPesquisa: textoPesquisa } }).then(res => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    listarUsuariosAtivos() {
        return this.http.get("usuario").then(res => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    listaPerfis() {
        return this.http.get(`/seguranca/perfil`).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    listarPermissoesDeUsuario(username: string) {
        return this.http.get(`usuario/${username}/perfil`).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    logar(usuario: Usuario) {
        return this.http
            .post(`login`, usuario, { withCredentials: true, headers: { "Access-Control-Allow-Origin": "*" } })
            .then(
                res => {
                    Vue.$cookies.set("jwt", res.data);
                    return res;
                },
                err => {
                    throw new Error(err);
                }
            );
    }

    logout() {
        return this.http
            .post(`logout`, {}, { withCredentials: true, headers: { "Access-Control-Allow-Origin": "*" } })
            .then((res: any) => {
                if (res && res.status >= 200 && res.status < 300) {
                    Vue.$cookies.set("jwt", "");
                    return res;
                }
            });
    }

    listaUsuarios() {
        return this.http.get("usuario/all").then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    obterUsuario(username: string): Promise<any> {
        return this.http.get(`usuario/${username}`).then(res => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    obterUsuarioPorUserName(username: string) {
        return this.http.get(`usuario/${username}`).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    obterUsuarioPessoaId(pessoaId: number) {
        return this.http.get(`usuario/pessoa/${pessoaId}`).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    alterarPermissoesUsuario(username: string, perfis: any[]) {
        return this.http.post(`/usuario/${username}/perfil`, perfis).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    inserirUsuario(usuario: UsuarioPessoa) {
        return this.http.post(`/usuario`, usuario).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    inserirMeuUsuario(usuario: UsuarioPessoa) {
        return this.http
            .post(`/usuario/meu`, usuario)
            .then((res: any) => {
                return res && res.status == 200 ? res.data : null;
            })
            .catch(e => {
                throw e;
            });
    }

    criarMinhaSenha() {
        return this.http.get(`/seguranca/password`).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    alterarMinhaSenha(usuVo: any) {
        return this.http.post(`/seguranca/minhaSenha/alterar`, usuVo).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    associarChatTelegram(telegramCodigoAssociacao: string) {
        return this.http
            .post(`/seguranca/bot/associar`, { telegramCodigoAssociacao: telegramCodigoAssociacao })
            .then((res: any) => {
                return res && res.status == 200 ? res.data : null;
            });
    }

    alterarUsuario(usuario: any) {
        return this.http.put(`/usuario`, usuario).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    obterMeusDados(): Promise<UsuarioPessoa> {
        return this.http.get(`/usuario/meusDados`).then((res: any) => {
            return res && res.status == 200 ? res.data : null;
        });
    }
}
