<template>
    <div>
        <div class="info-box m-0">
            <span class="info-box-icon bg-danger" v-if="type == 'error'">
                <i class="fas fa-exclamation-triangle"></i>
            </span>
            <span class="info-box-icon bg-success" v-else-if="type == 'success'">
                <i class="fas fa-check"></i>
            </span>
            <span class="info-box-icon bg-warning" v-else-if="type == 'warning'">
                <i class="fas fa-exclamation-triangle"></i>
            </span>
            <span class="info-box-icon bg-info" v-else>
                <i class="fas fa-info"></i>
            </span>
            <div class="info-box-content">
                <span class="info-box-text" style="white-space: inherit">
                    {{ msg }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        header: { required: false, type: String },
        botaoCancelar: { required: false, default: false, type: Boolean },
        msg: { required: false, default: "", type: String },
        type: { required: false, default: "info", type: String },
    },
    data() {
        return { showModal: true /*, msg: "" */ };
    },
    methods: {
        show() {
            this.showModal = true;
        },
    },
};
</script>
<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
</style>
