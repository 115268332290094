export default class Constantes {
    static get DATE_FORMAT() {
        return "YYYY-MM-DD h:mm:ss";
    }

    static get JWT_COOIKE() {
        return "jwt";
    }
    static get CRUD_INSERIR() {
        return "inserindo";
    }
    static get CRUD_LISTAR() {
        return "exibindo";
    }
    static get CRUD_DELETAR() {
        return "deletando";
    }
    static get CRUD_ALTERAR() {
        return "alterando";
    }

    static get STATUS_ATENDENDO() {
        return "Atendendo";
    }
    static get STATUS_VERIFICANDO() {
        return "Verificando recursos e elenco";
    }
    static get STATUS_AGUARDANDO_CLIENTE() {
        return "Aguardando ok do Cliente";
    }
    static get STATUS_FECHADA() {
        return "Fechada";
    }
    static get STATUS_FINALIZADA() {
        return "Finalizada";
    }
    static get STATUS_CANCELADA() {
        return "Cancelada";
    }

    static get STATUS_PAG_PENDENTE() {
        return "Pendente";
    }

    static get STATUS_PAG_PARCIALMENTE() {
        return "Parcialmente paga";
    }

    static get STATUS_PAG_PAGA() {
        return "Paga";
    }

    static get STATUS_PAG_EVENTO_DADO() {
        return "Evento dado";
    }

    // Categorias

    static get CATEGORIA_GASTO_AVULSO() {
        return "Gasto avulso";
    }

}
