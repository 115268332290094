import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import * as moment from "moment";

import NSProgress from "vue-nprogress-ts/src/nprogress";

// @ts-ignore
import Constantes from "./Constantes";

export default class AxiosHttp {
    static httpInstance: AxiosInstance;
    static nprogress: NSProgress = new NSProgress();
    static requestCount: number = 0;

    static decRequestCount() {
        if (AxiosHttp.requestCount > 0) AxiosHttp.requestCount--;
    }

    get http() {
        return AxiosHttp.httpInstance;
    }

    static _initialize = (() => {
        const axiosConfig = {
            baseURL: "https://api.cursocathedra.com.br/api",

            headers: {
                common: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            },
        };
        if (process.env.NODE_ENV != "production") {
            axiosConfig.baseURL = "http://localhost:8080/aulas/api";
            console.log(`Utilizando baseURL de ${process.env.NODE_ENV}: ` + axiosConfig.baseURL);
        }
        // @ts-ignore
        this.httpInstance = axios.create(axiosConfig);

        // Para sempre enviar o Header JWT de auth e configurar parametros de data
        this.httpInstance.interceptors.request.use(
            (config) => {
                if (AxiosHttp.requestCount > 0) this.nprogress.start();

                AxiosHttp.requestCount++;
                config.withCredentials = true;
                // @ts-ignore
                const jwt = window.$cookies.get("jwt");
                if (jwt && jwt != "null") {
                    // @ts-ignore
                    config.headers.common["Authorization"] = "Bearer " + jwt;
                }

                if (config.params) {
                    for (const paramName of Object.keys(config.params)) {
                        if (moment.isMoment(config.params[paramName])) {
                            config.params[paramName] = config.params[paramName].format(Constantes.DATE_FORMAT);
                        } else if (config.params[paramName] instanceof Date) {
                            config.params[paramName] = moment(config.params[paramName]).format(Constantes.DATE_FORMAT);
                        }
                    }
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        this.httpInstance.interceptors.response.use(
            (response) => {
                AxiosHttp.decRequestCount();
                if (AxiosHttp.requestCount <= 0) this.nprogress.done();

                return response;
            },
            (error) => {
                AxiosHttp.decRequestCount();
                if (AxiosHttp.requestCount <= 0) {
                    this.nprogress.done();
                }

                return Promise.reject(error);
            }
        );
    })();
}
