<template>
    <div class="wrapper" ref="container">
        <login v-if="!logged" @logged="updateLoggedUser()"></login>
        <div v-if="logged">
            <menus :versao="versao" @logout="logged = false"></menus>
            <div class="content-wrapper">
                <div class="container-fluid">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>
                                        <i v-if="$route.meta.icon" :class="'mr-2 nav-icon ' + $route.meta.icon"></i>
                                        {{ $route.meta.titulo }}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="content">
                        <div class="container-fluid">
                            <router-view></router-view>
                        </div>
                    </section>
                </div>
            </div>
            <nprogress-container></nprogress-container>
        </div>
        <vuedal></vuedal>
    </div>
</template>

<script>
import { routes } from "./configs/router";
import Menu from "./components/shared/menu/Menu.vue";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import SegurancaService from "./domain/Seguranca/SegurancaService";
import SistemaService from "./domain/sistema/SistemaService";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import Login from "./components/pages/login/Login.vue";
import Vue from "vue";

export default {
    name: "App",
    components: {
        menus: Menu,
        NprogressContainer: NprogressContainer,
        Login
    },
    data() {
        return {
            routes: routes,
            versao: "?.?.?",
            logged: false
        };
    },
    created() {
        this.loginPage = "/cathedra/adm_login.php";
        this.segService = new SegurancaService(this.$http);
        this.sistemaService = new SistemaService(this.$http);
        this.$http.interceptors.response.use(
            function(response) {
                if (response.status >= 400) console.log("ERRO NO APP", this);

                return response;
            },
            function(error) {
                let response = error.response;
                if (response) {
                    if (response.status == 400) {
                        this.$showErrorModal(response.data.message);
                        return Promise.reject(error);
                    } else if (response.status == 500) {
                        this.$showErrorModal(
                            "Erro não esperado, favor contate a admistração do Cathedra: " + response.data.message ||
                                JSON.stringify(response.data)
                        );
                        return Promise.reject(error);
                    } else if (response.status == 401) {
                        this.logged = false;
                        return response;
                    }
                } else {
                    return Promise.reject(error);
                }
            }.bind(this)
        );
        this.updateLoggedUser();
    },

    mounted() {
        this.sistemaService.version().then(systemVo => {
            this.versao = systemVo.version;
        });
    },

    methods: {
        updateLoggedUser() {
            if (this.$router.path != "/") this.$router.resolve("/");

            this.segService
                .loggedUser()
                .then(value => {
                    if (value && value.username && value.username.length > 1) {
                        value.perfis = [];
                        this.$store.commit("setLoggedUser", value);
                        this.logged = true;
                        this.segService.listarPermissoesDeUsuario(value.username).then(perfis => {
                            let logged = this.$loggedUser;
                            logged.perfis = perfis;
                            this.$store.commit("setLoggedUser", logged);
                        });
                    } else {
                        Vue.$cookies.set("jwt", "");
                    }
                })
                .catch(error => {
                    this.$showErrorModal("Erro de acesso aos dados: " + error);
                });
        }
    }
};
</script>

<style>
.pagina-enter,
.pagina-leave-active {
    opacity: 0;
}

.pagina-enter-active,
.pagina-leave-active {
    transition: opacity 0.5s;
}
#nprogress .bar {
    height: 12px;
    z-index: 91030;
}
#nprogress .spinner {
    z-index: 91030;
}
</style>
