<template>
    <div class="info-box">
        <span :class="'info-box-icon ' + iconeCor">
            <i :class="'fas ' + icone"></i>
        </span>
        <div class="info-box-content">
            <span class="info-box-text" style="white-space: inherit;">
                <slot></slot>
            </span>
            <span class="info-box-number" v-if="showNumber">
                <slot name="number"></slot>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icone: { type: String, required: false, default: "fa-info-circle" },
        iconeCor: { type: String, required: false, default: "bg-info" },
        showNumber: { type: Boolean, required: false, default: false }
    }
};
</script>

<style></style>
