import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/pages/home/Home.vue";
import SegurancaService from "../domain/Seguranca/SegurancaService";

Vue.use(VueRouter);
// type menuType = { path: string; name: string; component: any; meta: any; itens: any[] };

const menu: any[] = [
    { path: "*", component: Home, meta: { titulo: "Painel", menu: false } },
    {
        path: "/",
        name: "painel",
        component: Home,
        meta: { titulo: "Painel", menu: false },
    },
    {
        path: "/cursos",
        name: "cursos",
        component: () => import("../components/pages/curso/Cursos.vue"),
        meta: { titulo: "Cursos", menu: true, icon: "fas fa-book-open" },
        perfisPermitidos: [
            SegurancaService.PERFIL_ADM,
            SegurancaService.PERFIL_ESTAGIARIO,
            SegurancaService.PERFIL_VIDEOS,
        ],
    },
    {
        path: "/curso/[novo]",
        name: "cursoNovo",
        component: () => import("../components/pages/curso/CursoNovo.vue"),
        meta: { titulo: "Curso Novo", menu: false },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/curso/:cursoId/video/:materialId",
        name: "vídeo de curso",
        component: () => import("../components/pages/curso/AssistirCurso.vue"),
        meta: { titulo: "Curso", menu: false },
        perfisPermitidos: [SegurancaService.PERFIL_ADM, SegurancaService.PERFIL_ALUNO, SegurancaService.PERFIL_VIDEOS],
    },
    {
        path: "/curso/:id/:rodada?",
        name: "curso",
        component: () => import("../components/pages/curso/CursoPage.vue"),
        meta: { titulo: "Curso", menu: false },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/aluno/resetarSenha",
        name: "resetSenha",
        component: () => import("../components/pages/usuario/ResetarSenhaAluno.vue"),
        meta: { titulo: "Resetar senha de aluno", menu: true, icon: "fas fa-user" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/vendas/cupons",
        name: "cupons",
        component: () => import("../components/pages/vendas/CuponsPage.vue"),
        meta: { titulo: "Cupons", menu: true, icon: "fas fa-ticket-alt" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/vendas/cupom/:id",
        name: "cupom",
        component: () => import("../components/pages/vendas/CupomPage.vue"),
        meta: { titulo: "Cupons", menu: false, icon: "fas fa-ticket-alt" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/vendas/pedidos",
        name: "pedidos",
        component: () => import("../components/pages/vendas/AcompanharPedidos.vue"),
        meta: { titulo: "Acompanhar pedidos", menu: true, icon: "fas fa-money-check-alt" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/videos/orfaos",
        name: "videosOrfaos",
        component: () => import("../components/pages/curso/VideosOrfaos.vue"),
        meta: { titulo: "Vídeos orfãos", menu: true, icon: "fas fa-check" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/produtos",
        name: "produtos",
        component: () => import("../components/pages/vendas/ProdutosPage.vue"),
        meta: { titulo: "Produtos", menu: true, icon: "fas fa-boxes" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/produto/:id",
        name: "produto",
        component: () => import("../components/pages/vendas/ProdutoPage.vue"),
        meta: { titulo: "Produto", menu: false, icon: "fas fa-box" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/usuarios",
        name: "usuarios",
        component: () => import("../components/pages/usuario/UsuariosPage.vue"),
        meta: { titulo: "Usuários", menu: true, icon: "fas fa-users" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/usuario/:username",
        name: "usuario",
        component: () => import("../components/pages/usuario/UsuarioPage.vue"),
        meta: { titulo: "Usuário", menu: false, icon: "fas fa-user" },
        perfisPermitidos: [SegurancaService.PERFIL_ADM],
    },
    {
        path: "/marketing",
        name: "marketing",
        component: () => import("../components/pages/marketing/Marketing.vue"),
        meta: {
            titulo: "Marketing",
            menu: true,
            icon: "fas fa-envelope-open-text",
        },
        perfisPermitidos: [SegurancaService.PERFIL_ADM, SegurancaService.PERFIL_MARKETING],
    },
];

const routes: any[] = [];
menu.forEach((m) => {
    if (m.path) {
        routes.push(m);
    } else if (m.itens) {
        m.itens.forEach((m2: any) => {
            if (m2.path) routes.push(m2);
        });
    }
});

const router = new VueRouter({
    routes,
});

export { router, routes, menu };
