<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 class="h3">Bem vindo ao novo Cathedra!</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <info icone="fa-smile">
                    O Cathedra está sendo modernizado e essa é sua nova cara, já com o <em>jeitão</em> do 2.0. Ele será migrado aos poucos e, em breve,
                    você estará navegando apenas no novo Cathedra.
                </info>
            </div>
        </div>
    </div>
</template>

<script>
import Info from "../../shared/painel/Info.vue";
export default {
    components: { Info },

    data() {
        return {};
    },
};
</script>

<style>
.centralizado {
    text-align: center;
}

.lista-fotos {
    list-style: none;
}
.lista-fotos .lista-fotos-item {
    display: inline-block;
}
table,
th,
td {
    border: 1px;
    border-style: solid;
    border-color: gray;
}
</style>
