<template>
    <div class="login-page">
        <div class="login-box">
            <!-- /.login-logo -->
            <div class="border-cathedra card card-outline card-primary">
                <div class="card-header text-center">
                    <a href="https://cursocathedra.com.br" target="_blank">
                        <img class="w-100" src="../../../assets/img/logo_login.png" alt="Curso Cathedra" />
                    </a>
                </div>
                <div class="card-body bg-cathedra">
                    <h2 class="text-center">Área do Aluno</h2>
                    <p class="login-box-msg text-danger"></p>

                    <div class="input-group mb-3">
                        <input class="form-control" placeholder="email/cpf" v-model="usuario.username" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-user"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="senha" v-model="usuario.password" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8"></div>
                        <div class="col-4">
                            <button @click="logar" class="btn btn-primary btn-block">Entrar</button>
                        </div>
                    </div>

                    <p class="mb-1 text-xs">
                        <a href="../adm_esqueceu.php" style="color: black;">Esqueci minha senha</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SegurancaService from "../../../domain/Seguranca/SegurancaService";
import Usuario from "../../../domain/Seguranca/Usuario";

export default {
    name: "LoginPage",
    data() {
        return {
            usuario: new Usuario(),
        };
    },
    mounted() {
        this.segService = new SegurancaService(this.$http);
    },
    methods: {
        logar() {
            this.segService
                .logar(this.usuario)
                .then(() => {
                    this.$emit("logged", true);
                })
                .catch((resp) => {
                    console.error(resp);
                });
        },
    },
};
</script>

<style>
.bg-cathedra {
    background-color: rgb(241, 142, 20);
}
.border-cathedra {
    border-top-color: rgb(241, 142, 20) !important;
}
</style>
