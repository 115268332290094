//@ts-ignore
import jQuery from "jquery";
import "admin-lte/plugins/jquery/jquery";
import "admin-lte/plugins/jquery-ui/jquery-ui";
import "admin-lte/plugins/popper/popper";
import "admin-lte/plugins/bootstrap/js/bootstrap.bundle";

import "@fortawesome/fontawesome-free/css/all.css";
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/bootstrap4-light-purple/theme.css";

import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/plugins/icheck-bootstrap/";
import "admin-lte/dist/js/adminlte";
import "toastr/build/toastr.min.css";

import "select2/dist/js/select2.full.min";
import "select2/dist/css/select2.min.css";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";

import "./assets/css/cathedra.css";

import "select2";

import Vue from "vue";
//@ts-ignore
import App from "./App.vue";

import { router } from "./configs/router";
//@ts-ignore
import moment from "moment";
import "moment/locale/pt-br";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import "./validation";
//@ts-ignore
import Modal from "./components/shared/modal/Modal.vue";
import VueCookie from "vue-cookies";
//@ts-ignore
import NProgress from "vue-nprogress";
//@ts-ignore
import { default as Vuedals, Component as Vuedal, Bus as VuedalsBus } from "vuedals";

import Vuex from "vuex";
import SegurancaService from "./domain/Seguranca/SegurancaService";
import AxiosHttp from "./domain/AxiosHttp";

import primeVueConfig from "./configs/primeVueConfig";

//@ts-ignore
import PrimeVue from "primevue/config";
import URLS from "./domain/UrlsVO";
// import DataTable from "primevue/datatable";
// import Column from "primevue/column";

Vue.use(PrimeVue, primeVueConfig);
// Vue.component("DataTable", DataTable);
// Vue.component("Column", Column);

Object.defineProperty(Vue.prototype, "$jQuery", { value: jQuery });
jQuery.fn.select2.defaults.set("theme", "bootstrap4");

Vue.use(Vuex);
const loggedUser = new Vuex.Store({
    state: { loggedUser: {} },
    mutations: {
        setLoggedUser(state, novoValor) {
            state.loggedUser = novoValor;
        },
    },
});

Vue.use(VueCookie, { expires: "7d", domain: `.${URLS.dominio}`, path: "/" });

// Para Sempre exibir o progresso quando enaivar requisição AJAX
Vue.use(NProgress);
const nprogress = new NProgress();

Vue.prototype.$http = new AxiosHttp().http;

Vue.use(Vuedals);

Vue.component("vee-validate", ValidationProvider);
Vue.component("vee-observer", ValidationObserver);
Vue.component("modal", Modal);
Vue.component("vuedal", Vuedal);

const realFormater = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
});
const percFormater = Intl.NumberFormat("pt-BR", {
    style: "percent",
    currency: "BRL",
    minimumFractionDigits: 2,
});

Vue.filter("date", function (value: any) {
    if (value != 0 && !value) return "";
    else {
        return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    }
});

Vue.filter("dateTime", function (value: any) {
    if (value != 0 && !value) return "";
    else {
        return moment(value, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    }
});

Vue.filter("toCurrency", function (value: any) {
    if (value != 0 && !value) return "";
    else {
        return realFormater.format(value);
    }
});
Vue.filter("toPercent", function (value: any) {
    if (value != 0 && !value) return "";
    else {
        return percFormater.format(value);
    }
});

Vue.prototype.$SegurancaService = SegurancaService;

Vue.mixin({
    computed: {
        $loggedUser() {
            //@ts-ignore
            return this.$store.state.loggedUser;
        },
    },
    methods: {
        /**
         *
         * @param {Array} perfis
         * @returns
         */
        $possuiPerfil(perfis) {
            //@ts-ignore
            return this.$loggedUser.perfis.filter((perf) => perfis.includes(perf.nome)).length > 0;
        },
        $showAlertModal(msg) {
            VuedalsBus.$emit("new", {
                component: Modal,
                props: {
                    msg: msg,
                    type: "warning",
                },
            });
        },
        $showErrorModal(msg) {
            VuedalsBus.$emit("new", {
                component: Modal,
                props: {
                    msg: msg,
                    type: "error",
                },
            });
        },
        $showSuccessModal(msg) {
            VuedalsBus.$emit("new", {
                component: Modal,
                props: {
                    msg: msg,
                    type: "success",
                },
            });
        },
    },
});

new Vue({
    //@ts-ignore
    nprogress: nprogress,
    el: "#app",
    router: router,
    store: loggedUser,
    render: (h) => h(App),
});
