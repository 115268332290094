export class UrlsVO {
    dominio: string;
    aulas: string;
    php: string;
    vendas: string;

    constructor() {
        this.dominio = "cursocathedra.com.br";
        this.aulas = `https://aulas.${this.dominio}/`;
        this.php = `https://php.${this.dominio}/`;
        this.vendas = `https://${this.dominio}/`;

        if (process.env.NODE_ENV != "production") {
            this.dominio = "localhost";
            this.aulas = `http://${this.dominio}:8081/`;
            this.php = `http://${this.dominio}:8090/`;
            this.vendas = `http://${this.dominio}:8082/`;
        }
    }
}
const URLS = new UrlsVO();

export default URLS;
