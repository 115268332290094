
import { Vue, Component, Prop } from "vue-property-decorator";
import "../../../assets/img/logo.png";
import Perfil from "../../../domain/Seguranca/Perfil";
import SegurancaService from "../../../domain/Seguranca/SegurancaService";
import { menu } from "../../../configs/router";
import URLS, { UrlsVO } from "../../../domain/UrlsVO";

@Component({ components: {} })
export default class Menu extends Vue {
    @Prop({ required: false, default: "X.Y.Z" })
    versao: string;
    segService: SegurancaService;
    menuExpandido: { [name: string]: boolean } = {};
    urls: UrlsVO = URLS;

    created() {
        this.segService = new SegurancaService();
    }

    get rotasPermitidas() {
        // @ts-ignore
        let loggedUser = this.$loggedUser;

        // return this.rotas.filter(rota => {
        return menu.filter((rota) => {
            return (
                rota.meta.menu &&
                (!rota.perfisPermitidos ||
                    (loggedUser.perfis &&
                        rota.perfisPermitidos.filter((pf: any) =>
                            loggedUser.perfis.map((p: Perfil) => p.nome).includes(pf)
                        ).length > 0))
            );
        });
    }

    logout() {
        this.segService.logout().then(() => this.$emit("logout", true));
    }

    toggleMenu(route: any) {
        if (this.menuExpandido[route.meta.titulo]) this.$set(this.menuExpandido, route.meta.titulo, false);
        else this.$set(this.menuExpandido, route.meta.titulo, true);
    }
}
